// assets
import { IconClipboardList, IconCheckupList, IconLayoutDistributeHorizontal, IconListCheck, IconListDetails } from '@tabler/icons';

// constant
const icons = { IconClipboardList, IconCheckupList, IconLayoutDistributeHorizontal, IconListCheck, IconListDetails };
// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const giantopex = {
    id: 'GiantOpEx',
    title: 'giant-opex',
    type: 'group',
    icon: icons.IconLayoutDistributeHorizontal,
    children: [
        {
            id: 'GiantOpEx_MyForms',
            title: 'my-forms',
            type: 'item',
            icon: icons.IconClipboardList,
            url: '/GIANT-OpEx/My-forms'
        },
        {
            id: 'GiantOpEx_MyWorkflows',
            title: 'my-workflows',
            type: 'collapse',
            icon: icons.IconCheckupList,
            children: [
                {
                    id: 'GiantOpEx_SetpointManagement',
                    title: 'setpoint-management',
                    type: 'item',
                    icon: icons.IconListDetails,
                    url: '/GIANT-OpEx/my-workflows/setpoint-management'
                },
                {
                    id: 'GiantOpEx_ApplicationForApproval',
                    title: 'application-for-approval',
                    type: 'item',
                    icon: icons.IconListCheck,
                    url: '/GIANT-OpEx/my-workflows/Application-for-approval'
                }
            ]
        }
    ]
};

export default giantopex;
