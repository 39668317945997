import { ButtonBase, Container, Modal, ThemeProvider, Typography, useMediaQuery, Box, Grid, FormControl, FormHelperText, OutlinedInput, InputLabel, Avatar, Button} from '@mui/material';



import { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';


import { IconCheck } from '@tabler/icons';

import { useTheme } from '@mui/material/styles';

// project imports
import useScriptRef from 'hooks/useScriptRef';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SET_CONFIRMRESET_OPEN } from 'store/actions';


function getModalStyle() {
    const top = 50 ;
    const left = 50 ;

    return {

       top: `${top}%`,
       left: `${left}%`,
       transform: `translate(-${top}%, -${left}%)`,
       borderRadius: "20px",
    };
}



const ConfirmForgotPassword = () => {

   
    const [modalStyle] = useState(getModalStyle);
    const theme = useTheme();

    const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const scriptedRef = useScriptRef();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const open = useSelector((state) => state.customization.isConfirmResetOpen);
    const [errorMessage, setErrorMessage] = useState("");
    const [openSuccessfullyReset, setOpenSuccessfullyReset] =useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const urlToken = searchParams.get("token")
    console.log("Token " + urlToken)



    const useStyles = makeStyles(theme => ({
        paper: {
            position: "absolute",
            width: matchesMdDown ? "90%": "40%",
            padding: "30px",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            
            outline: "none",
          
        }
      }));
    const classes = useStyles();

    return(

        <>
        <Modal
            open={openSuccessfullyReset}
            onClose={()=> setOpenSuccessfullyReset(false)}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            >
                <div style={modalStyle} className={classes.paper}>
                    <ThemeProvider theme={theme}>
                        <Container component="main" style={{paddingLeft: "0", paddingRight: "0"}}>

                            <Typography>
                                <Typography variant="h2" align="center" sx={{margin: "0", pb: 2}}>
                                
                                    <IconCheck stroke={1.5} size="1.6rem" style={{ verticalAlign: "middle"}} /> Congratulations!
                                </Typography>

                                <Typography variant="body2" align="justify" sx={{mb: 2, textAlign:"center"}}>
                                    Your password has been changed successfully. 
                                </Typography>

                            </Typography>
                        </Container>
                    </ThemeProvider>
                </div>
            
            </Modal>
        <Modal
        open={open}
        onClose={()=> {
            dispatch({ type: SET_CONFIRMRESET_OPEN , isConfirmResetOpen: false });
            setErrorMessage("")
            navigate("/");
        }
    }
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <ThemeProvider theme={theme}>
                    <Container component="main" style={{paddingLeft: "15px", paddingRight: "15px", textAlign: "center"}}>
                        
                        <Typography component="h1" variant="h5" sx={{ marginBottom: "5px", fontSize: "22px", padding: "5px"}}>
                            Reset Password
                        </Typography>

                        <div style={{color: "red", textAlign: "center"}}>{errorMessage}</div>

                       

                        <Formik
                            initialValues={{
                                password: '',
                                confirmPassword: ''
                                
                            }}
                            validationSchema={Yup.object().shape({
                                password: Yup.string()
                                .required('Password is required'),
                                confirmPassword: Yup.string()
                                .oneOf([Yup.ref('password'), null], "Passwords don't match!")
                                .required('Confirm Password is required')
                            })}

                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                console.log(values)
                                console.log(scriptedRef.current)
                                try {
                                    if (scriptedRef.current) {
                                        const response = await axios.post('https://demo.evniko-giant.com/api/v1/users/password_reset_confirmed', {password: values.password}, {
                                            params: {
                                                token: urlToken
                                            }
                                        });
                                        console.log(response)
                                        if (response.status === 200 || response.status === 201) {
                                            setStatus({ success: true });
                                            setSubmitting(false);
                                            dispatch({ type: SET_CONFIRMRESET_OPEN , isConfirmResetOpen: false });
                                            setOpenSuccessfullyReset(true)
                                        } else {
                                            throw new Error(response.data.message);
                                        }
                                    }
                                } catch (err) {
                                    console.error(err);
                                    if (scriptedRef.current) {
                                        setErrorMessage("We weren't able to reset your password. Please retry.")
                                        setStatus({ success: false });
                                        setErrors({ submit: err.message });
                                        setSubmitting(false);
                                    }
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                    <Grid item xs={12} sm={12}>
                                        <FormControl margin="normal" size="medium" fullWidth required error={Boolean(touched.password && errors.password)} >
                                            <InputLabel htmlFor="passwor-login">Password</InputLabel>
                                            <OutlinedInput
                                                size="medium" 
                                                label="Password"
                                                id="password"
                                                type="password"
                                                value={values.password}
                                                name="password"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                inputProps={{}}
                                            />
                                            {touched.password && errors.password && (
                                                <FormHelperText error id="password">
                                                    {errors.password}
                                                </FormHelperText>
                                            )}
                                        </FormControl>

                                        <FormControl margin="normal" size="medium" fullWidth required error={Boolean(touched.confirmPassword && errors.confirmPassword)} >
                                            <InputLabel htmlFor="passwor-login">Confirm Password</InputLabel>
                                            <OutlinedInput
                                                size="medium" 
                                                label="Confirm Password"
                                                id="confirmPassword"
                                                type="password"
                                                value={values.confirmPassword}
                                                name="confirmPassword"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                inputProps={{}}
                                            />
                                            {touched.confirmPassword && errors.confirmPassword && (
                                                <FormHelperText error id="confirmPassword">
                                                    {errors.confirmPassword}
                                                </FormHelperText>
                                            )}
                                        </FormControl>

                                        <Button
                                            
                                            type="submit"
                                            size="large"
                                            fullWidth
                                            variant="contained"
                                            backgroundColor="secondaryMain"
                                            sx={{ mt: 2, mb: 1, backgroundColor: theme.palette.secondary.dark, fontSize: "16px" }}
                                        >
                                           Reset Password
                                        </Button>
                                    </Grid>
                                </Box>


                            )}
                        </Formik>

                        

                        
                    </Container>
                </ThemeProvider>
            </div>
        
        </Modal>
    </>

    )

}

export default ConfirmForgotPassword;