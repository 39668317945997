import dashboard from "./dashboard";
import pages from "./pages";
import utilities from "./utilities";
import other from "./other";
import giantopex from "./giantopex";
import giantquality from "./giantquality";
import giantsupplychain from "./giantsupplychain";
import giantintelligence from "./giantintelligence";
import gianttoolboxmanager from "./gianttoolboxmanager";
import profile from "./profile";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    // dashboard,
    giantintelligence,
    // pages,
    giantopex,
    giantquality,
    giantsupplychain,
    gianttoolboxmanager,
  ], //, utilities, other]
};

export default menuItems;
