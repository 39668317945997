// assets
import { IconSearch, IconEdit, IconPlus, IconClipboardCheck, IconDatabase, IconDiscountCheck, IconDeviceFloppy } from '@tabler/icons';

// constant
const icons = { IconSearch, IconEdit, IconPlus, IconClipboardCheck, IconDatabase, IconDiscountCheck, IconDeviceFloppy };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const giantquality = {
    id: 'GiantQuality',
    title: 'giant-quality',
    icon: icons.IconClipboardCheck,
    type: 'group',
    children: [
        {
            id: 'GiantQuality_QualityData',
            title: 'quality-data',
            type: 'item',
            icon: icons.IconDiscountCheck,
            url: '/GIANT-Quality/Quality-Data'
        },
        {
            id: 'GiantQuality_ReserveSamples',
            title: 'reserve-samples',
            type: 'collapse',
            icon: icons.IconDeviceFloppy,
            children: [
                {
                    id: 'GiantQuality_Add',
                    title: 'add',
                    type: 'item',
                    icon: icons.IconPlus,
                    url: '/GIANT-Quality/Reserve-Samples/Add'
                },
                {
                    id: 'GiantQuality_MapStorage',
                    title: 'map-storage',
                    type: 'item',
                    icon: icons.IconDatabase,
                    url: '/GIANT-Quality/Reserve-Samples/Map-Storage'
                },
                {
                    id: 'GiantQuality_Search',
                    title: 'search',
                    type: 'item',
                    icon: icons.IconSearch,
                    url: '/GIANT-Quality/Reserve-Samples/Search'
                },
                {
                    id: 'GiantQuality_Edit',
                    title: 'edit',
                    type: 'item',
                    icon: icons.IconEdit,
                    url: 'GIANT-Quality/Reserve-Samples/Edit'
                }
            ]
        }
    ]
};

export default giantquality;
