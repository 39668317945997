import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_LOGIN_OPEN, SET_REGISTER_OPEN } from 'store/actions';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
    MenuItem,
    Select,
    Avatar,
    CssBaseline,
    Link,
    Paper,
    LockOutlinedIcon,
    useMediaQuery,
    Modal,
    Dialog,
    DialogContent,


} from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import registerPhoto from '../../../../assets/images/register_project5.png';


// material-ui
import { useTheme } from '@mui/material/styles';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import axios from 'axios';

import { useState } from 'react';

import ConfirmationModal from './ConfirmationModal';
import { useTranslation } from 'react-i18next';


export default function Register() {
    const open = useSelector((state) => state.customization.isRegisterOpen);
    const dispatch = useDispatch();
    const theme = useTheme();
    theme.typography.fontSize = 1;

    const { t } = useTranslation();

    const [confirmEmailModalOpen, setConfirmEmailModalOpen] = useState(false);

    const useStyles = makeStyles(theme => ({
        paper: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            outline: "none",
        }
    }));
    const classes = useStyles();


    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));


    const [showPassword, setShowPassword] = useState(false);
    const [checkedTerms, setCheckedTerms] = useState(false);


    const [checkedPromotions, setCheckedPromotions] = useState(false);

    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };


    const scriptedRef = useScriptRef();
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };


    return (
        <>
            <ConfirmationModal open={confirmEmailModalOpen} setOpen={setConfirmEmailModalOpen} />

            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={open}
                onClose={() => dispatch({ type: SET_REGISTER_OPEN, isRegisterOpen: false })}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                PaperProps={{
                    style: {
                        maxHeight: '98%',
                        borderRadius: "30px"
                    }
                }}
            >
                <DialogContent sx={{ padding: "0" }}>
                    <div className={classes.paper}>
                        <ThemeProvider theme={theme}>
                            <Grid container component="main" sx={{}}>

                                <CssBaseline />
                                <Grid
                                    container
                                    xs={false}
                                    sm={4}
                                    md={7}
                                    sx={{
                                        background: `linear-gradient(to right bottom, ${theme.palette.secondary.main},
                        ${theme.palette.secondary.light})`,
                                        paddingTop: "2%"

                                    }}
                                >

                                    <Grid item>

                                        <h1 style={{
                                            justifyContent: "center",
                                            alignContent: "center",
                                            textAlign: "center",
                                            lineHeight: "26pt",
                                            color: "#CDF7E9",
                                            fontSize: "26px"
                                        }}>
                                            <span style={{ color: "#4E4E4E" }}>GIANT </span>
                                            - Your
                                            <span style={{ color: "#4E4E4E" }}> G</span>eneric <span style={{ color: "#4E4E4E" }}>I</span>nformation <span style={{ color: "#4E4E4E" }}>AN</span>alysis <span style={{ color: "#4E4E4E" }}>T</span>oolbox
                                        </h1>

                                        <p style={{
                                            justifyContent: "center",
                                            alignContent: "center",
                                            textAlign: "center",
                                            lineHeight: "26pt",
                                            color: "#CDF7E9",
                                            fontSize: "20px",
                                            fontWeight: "bold"
                                        }}>
                                            {t('slogan')}
                                        </p>

                                        <img src={registerPhoto} alt="register-photo" style={{ width: "90%", margin: "auto", paddingTop: "2%", paddingBottom: "5%", display: matchDownSM ? "none" : "block" }} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                                    <Box
                                        sx={{
                                            my: 6,
                                            mx: 2,

                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Avatar sx={{ m: 1, bgcolor: theme.palette.secondary.dark, color: theme.palette.secondary.light }}>

                                        </Avatar>
                                        <Typography component="h1" variant="h5" sx={{ marginBottom: "5px", fontSize: "20px" }}>
                                            {t('sign-up')}
                                        </Typography>
                                        <Formik
                                            initialValues={{
                                                first_name: '',
                                                last_name: '',
                                                email: '',
                                                department: '',
                                                heard_about_us: '',
                                                password: '',
                                                position: '',
                                                company_name: '',
                                                industry: '',
                                                checkedTerms: false,
                                                agreed_to_news: false,
                                                submit: null
                                            }}
                                            validationSchema={Yup.object().shape({
                                                email: Yup.string().email(t('valid-email')).max(255).required(t('required-email')),
                                                password: Yup.string().max(255).required(t('required-pass')),
                                                first_name: Yup.string().required(t('valid-first-name')),
                                                last_name: Yup.string().required(t('valid-last-name')),
                                                position: Yup.string().required(t('valid-role/position')),
                                                company_name: Yup.string().required(t('valid-company-name')),
                                                industry: Yup.string().required(t('valid-industry')),
                                                checkedTerms: Yup.bool().oneOf([true], t('terms-and-conditions'))

                                            })}

                                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                                console.log(values)
                                                console.log(scriptedRef.current)
                                                try {
                                                    if (scriptedRef.current) {
                                                        const response = await axios.post('https://demo.evniko-giant.com/api/v1/users/create', values);
                                                        console.log(response)
                                                        if (response.status === 200 || response.status === 201 ) {
                                                            setStatus({ success: true });
                                                            setSubmitting(false);
                                                            dispatch({ type: SET_REGISTER_OPEN, isRegisterOpen: false })
                                                            setConfirmEmailModalOpen(true)
                                                        } else {
                                                            throw new Error(response.data.message);
                                                        }
                                                    }
                                                } catch (err) {
                                                    console.error(err);
                                                    if (scriptedRef.current) {
                                                        setStatus({ success: false });
                                                        setErrors({ submit: err.message });
                                                        setSubmitting(false);
                                                    }
                                                }
                                            }}

                                        >
                                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (


                                                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                                    <Grid container spacing={{ xs: 1 }} columnSpacing={{ xs: 2 }}>

                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl size="small" fullWidth required error={Boolean(touched.first_name && errors.first_name)} >
                                                                <InputLabel htmlFor="outlined-adornment-first_name-register">{t('first-name')}</InputLabel>
                                                                <OutlinedInput
                                                                    size="small"
                                                                    label="First Name"
                                                                    id="outlined-adornment-first_name-register"
                                                                    type="text"
                                                                    value={values.first_name}
                                                                    name="first_name"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    inputProps={{ style: { fontSize: 10 } }}
                                                                />

                                                                <FormHelperText size="small" error sx={{ height: "10px", marginTop: "0px", marginBottom: "4px" }} id="standard-weight-helper-first_name--register">
                                                                    {touched.first_name && errors.first_name && (
                                                                        errors.first_name
                                                                    )}
                                                                </FormHelperText>

                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl size="small" fullWidth required error={Boolean(touched.last_name && errors.last_name)}>
                                                                <InputLabel htmlFor="outlined-adornment-last_name-register">{t('last-name')}</InputLabel>
                                                                <OutlinedInput
                                                                    size="small"
                                                                    label="last_name"
                                                                    id="outlined-adornment-lastName-register"
                                                                    type="text"
                                                                    value={values.last_name}
                                                                    name="last_name"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    inputProps={{ style: { fontSize: 10 } }}
                                                                />
                                                                <FormHelperText error sx={{ height: "10px", marginTop: "0px", marginBottom: "4px" }} id="standard-weight-helper-last_name--register">
                                                                    {touched.last_name && errors.last_name && (
                                                                        errors.last_name
                                                                    )}
                                                                </FormHelperText>

                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl size="small" fullWidth>
                                                                <InputLabel htmlFor="outlined-adornment-title-register">{t('title')}</InputLabel>
                                                                <OutlinedInput
                                                                    size="small"
                                                                    label="title"
                                                                    id="outlined-adornment-title-register"
                                                                    type="text"
                                                                    value={values.title}
                                                                    name="title"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    inputProps={{ style: { fontSize: 10 } }}
                                                                />
                                                                <FormHelperText error sx={{ height: "10px", marginTop: "0px", marginBottom: "4px" }} id="standard-weight-helper-title--register">

                                                                </FormHelperText>


                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl size="small" fullWidth required error={Boolean(touched.position && errors.position)}>
                                                                <InputLabel htmlFor="outlined-adornment-position-register">{t('role-position')}</InputLabel>
                                                                <OutlinedInput
                                                                    size="small"
                                                                    label="Role / Position"
                                                                    id="outlined-adornment-position-register"
                                                                    type="text"
                                                                    value={values.position}
                                                                    name="position"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    inputProps={{ style: { fontSize: 10 } }}
                                                                />

                                                                <FormHelperText error sx={{ height: "10px", marginTop: "0px", marginBottom: "4px" }} id="standard-weight-helper-position--register">
                                                                    {touched.position && errors.position && (
                                                                        errors.position
                                                                    )}
                                                                </FormHelperText>

                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl size="small" fullWidth required error={Boolean(touched.company_name && errors.company_name)}>
                                                                <InputLabel htmlFor="outlined-adornment-company_name-register">{t('company-name')}</InputLabel>
                                                                <OutlinedInput
                                                                    size="small"
                                                                    label="Company Name"
                                                                    id="outlined-adornment-company_name-register"
                                                                    type="text"
                                                                    value={values.company_name}
                                                                    name="company_name"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    inputProps={{ style: { fontSize: 10 } }}
                                                                />

                                                                <FormHelperText error sx={{ height: "10px", marginTop: "0px", marginBottom: "4px" }} id="standard-weight-helper-company_name--register">
                                                                    {touched.company_name && errors.company_name && (
                                                                        errors.company_name
                                                                    )}
                                                                </FormHelperText>

                                                            </FormControl>
                                                        </Grid>



                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl size="small" fullWidth required error={Boolean(touched.industry && errors.industry)}>
                                                                <InputLabel htmlFor="outlined-adornment-industry-register">{t('industry')}</InputLabel>
                                                                <OutlinedInput
                                                                    size="small"
                                                                    label="Industry"
                                                                    id="outlined-adornment-industy-register"
                                                                    type="text"
                                                                    value={values.industry}
                                                                    name="industry"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    inputProps={{ style: { fontSize: 10 } }}
                                                                />

                                                                <FormHelperText error sx={{ height: "10px", marginTop: "0px", marginBottom: "4px" }} id="standard-weight-helper-industry--register">
                                                                    {touched.industry && errors.industry && (
                                                                        errors.industry
                                                                    )}
                                                                </FormHelperText>

                                                            </FormControl>
                                                        </Grid>






                                                        <Grid item xs={12} sm={12}>
                                                            <FormControl size="small" fullWidth required error={Boolean(touched.email && errors.email)} >
                                                                <InputLabel htmlFor="outlined-adornment-email-register">{t('email-username')}</InputLabel>
                                                                <OutlinedInput
                                                                    size="small"
                                                                    label="Email Address / Username"
                                                                    id="outlined-adornment-email-register"
                                                                    type="email"
                                                                    value={values.email}
                                                                    name="email"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    inputProps={{ style: { fontSize: 10 } }}
                                                                />

                                                                <FormHelperText error sx={{ height: "10px", marginTop: "0px", marginBottom: "4px" }} id="standard-weight-helper-text--register">
                                                                    {touched.email && errors.email && (
                                                                        errors.email
                                                                    )}
                                                                </FormHelperText>

                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <FormControl
                                                                size="small"
                                                                fullWidth
                                                                error={Boolean(touched.password && errors.password)}
                                                            >
                                                                <InputLabel htmlFor="outlined-adornment-password-register">{t('password')}</InputLabel>
                                                                <OutlinedInput
                                                                    size="small"
                                                                    id="outlined-adornment-password-register"
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    value={values.password}
                                                                    name="password"
                                                                    label="Password"
                                                                    onBlur={handleBlur}
                                                                    onChange={(e) => {
                                                                        handleChange(e);
                                                                        changePassword(e.target.value);
                                                                    }}
                                                                    endAdornment={
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={handleClickShowPassword}
                                                                                onMouseDown={handleMouseDownPassword}
                                                                                edge="end"
                                                                                size="large"
                                                                            >
                                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    }
                                                                    inputProps={{ style: { fontSize: 10 } }}
                                                                />

                                                                <FormHelperText error sx={{ height: "10px", marginTop: "0px", marginBottom: "4px" }} id="standard-weight-helper-text-password-register">
                                                                    {touched.password && errors.password && (
                                                                        errors.password
                                                                    )}
                                                                </FormHelperText>

                                                            </FormControl>
                                                            {strength !== 0 && (
                                                                <FormControl fullWidth>
                                                                    <Box sx={{ mb: 2 }}>
                                                                        <Grid container spacing={2} alignItems="center">
                                                                            <Grid item>
                                                                                <Box
                                                                                    style={{ backgroundColor: level?.color }}
                                                                                    sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography variant="subtitle1" fontSize="0.75rem">
                                                                                    {level?.label}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </FormControl>
                                                            )}
                                                        </Grid>


                                                    </Grid>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item>
                                                            <FormControl>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            required
                                                                            size="small"
                                                                            value={values.checkedTerms}
                                                                            checked={checkedTerms}
                                                                            onChange={(event) => {
                                                                                handleChange(event);
                                                                                setCheckedTerms(event.target.checked)
                                                                            }
                                                                            }
                                                                            name="checkedTerms"
                                                                            color="primary"
                                                                            sx={{ paddingBottom: "0px", paddingTop: "0px" }}
                                                                        />
                                                                    }
                                                                    label={
                                                                        <Typography variant="subtitle1">
                                                                            {t('agree-with')} 
                                                                            <Typography variant="subtitle1" component={Link} to="#">
                                                                                {t('agree-terms-and-conditions')}
                                                                            </Typography>
                                                                        </Typography>
                                                                    }
                                                                />

                                                                <FormHelperText size="small" error sx={{ height: "10px", marginTop: "0px", marginBottom: "6px" }} id="standard-weight-helper-checkedTerms--register" >
                                                                    {touched.checkedTerms && errors.checkedTerms && (


                                                                        errors.checkedTerms
                                                                    )}
                                                                </FormHelperText>
                                                            </FormControl>


                                                        </Grid>


                                                        <Grid item>

                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        checked={checkedPromotions}
                                                                        onChange={(event) => {
                                                                            handleChange(event);
                                                                            setCheckedPromotions(event.target.checked)}}
                                                                        name="agreed_to_news"
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={


                                                                    <Typography variant="subtitle1" to="#" sx={{ lineHeight: "1.2" }}>
                                                                        {t('marketing-promotions')}
                                                                    </Typography>

                                                                }
                                                            />
                                                        </Grid>



                                                    </Grid>

                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        backgroundColor="secondaryMain"

                                                        sx={{ mt: 3, mb: 2, backgroundColor: theme.palette.secondary.dark }}
                                                    >
                                                        {t('sign-up')}
                                                    </Button>
                                                    <Grid container>

                                                        <Grid item >
                                                            <Link onClick={() => {
                                                                dispatch({ type: SET_LOGIN_OPEN, isLoginOpen: true });
                                                                dispatch({ type: SET_REGISTER_OPEN, isRegisterOpen: false });
                                                            }}

                                                                sx={{ borderRadius: '12px', color: "#2AA0A9", fontWeight: "bold", }}>
                                                                {t('have-account')}
                                                            </Link>
                                                        </Grid>
                                                    </Grid>

                                                </Box>
                                            )}
                                        </Formik>
                                    </Box>

                                </Grid>
                            </Grid>
                        </ThemeProvider>
                    </div>
                </DialogContent>
            </Dialog>



        </>
    );
}