import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import Register from 'views/pages/authentication/auth-forms/Register';
import MainLayout from 'layout/MainLayout';

// login option 3 routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));


// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/login',
            element: <DashboardDefault openLogin={true}/>
        },
        {
            path: '/register-page',
            element: <Register />
        },
        {
            path: '/confirm-reset-password',
            element: <DashboardDefault openConfirmReset={true} />
        }
    ]
};

export default AuthenticationRoutes;
