// assets
import {
  IconHome,
  IconEdit,
  IconFolderPlus,
  IconBulb,
  IconLayoutDashboard,
  IconChartInfographic,
  IconSubtask,
  IconDeviceDesktopAnalytics,
  IconDashboard,
  IconZoomCheck,
  IconZoomExclamation,
  IconHeartRateMonitor,
  IconChartLine,
  IconChartBar,
  IconFileAnalytics,
  IconGridDots,
  IconChartAreaLine,
  IconFilePlus,
  IconReportAnalytics,
  IconTool,
  IconGauge,
  IconAsset,
  IconAlertCircle,
  IconClipboardList,
  IconLoader,
  IconEye,
  IconActivityHeartbeat
} from "@tabler/icons";

// constant
const icons = {
  IconHome,
  IconEdit,
  IconFolderPlus,
  IconBulb,
  IconLayoutDashboard,
  IconChartInfographic,
  IconSubtask,
  IconDeviceDesktopAnalytics,
  IconDashboard,
  IconZoomCheck,
  IconZoomExclamation,
  IconHeartRateMonitor,
  IconChartLine,
  IconChartBar,
  IconFileAnalytics,
  IconGridDots,
  IconChartAreaLine,
  IconFilePlus,
  IconReportAnalytics,
  IconTool,
  IconGauge,
  IconAsset,
  IconAlertCircle,
  IconClipboardList,
  IconLoader,
  IconEye,
  IconActivityHeartbeat
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const giantintelligence = {
  id: "GiantIntelligence",
  title: "giant-intelligence-title",
  type: "group",
  icon: icons.IconBulb,
  children: [
    {
      id: "GiantIntelligence_Home",
      title: "home",
      type: "item",
      icon: icons.IconHome,
      url: "/dashboard/default",
    },
    {
      id: "GiantIntelligence_MyDashboards",
      title: "my-dashboards",
      type: "collapse",
      icon: icons.IconLayoutDashboard,
      children: [
        {
          id: "GiantIntelligence_New",
          title: "new",
          type: "collapse",
          icon: icons.IconFilePlus,
          children: [
            {
              id: "GiantIntelligence_GenericDashboard",
              title: "generic-dashboard",
              type: "item",
              icon: icons.IconFileAnalytics,
              url: "/GIANT-Intelligence/My-Dashboard/generic-dashboard",
            },
            {
              id: "GiantIntelligence_TimeSeriesDashboard",
              title: "time-series",
              type: "item",
              icon: icons.IconChartAreaLine,
              url: "/GIANT-Intelligence/My-Dashboard/time-series-dashboard",
            },
            {
              id: "GiantIntelligence_PatternRecognition",
              title: "pattern-recognition",
              type: "item",
              icon: icons.IconGridDots,
              url: "/GIANT-Intelligence/My-Dashboard/pattern-recognition",
            },
            {
              id: "GiantIntelligence_DeepDataAnalysis ",
              title: "deep-data-analysis",
              type: "item",
              icon: icons.IconChartBar,
              url: "/GIANT-Intelligence/My-Dashboard/deep-data-analysis ",
            },
          ],
        },
        {
          id: "GiantIntelligence_OEE",
          title: "oee",
          type: "item",
          icon: icons.IconGauge,
          url: "/GIANT-Intelligence/My-Dashboard/oee",
        },
        {
          id: "GiantIntelligence_AnomalyDetection",
          title: "anomaly-detection",
          type: "item",
          icon: icons.IconAlertCircle,
          url: "/GIANT-Intelligence/My-Dashboard/anomaly-detection",
        },
        {
          id: "GiantIntelligence_ProductionMonitoring",
          title: "production-monitoring",
          type: "item",
          icon: icons.IconAsset,
          url: "/GIANT-Intelligence/My-Dashboard/production-monitoring",
        },
        {
          id: "GiantIntelligence_Maintenance",
          title: "Maintenance",
          type: "collapse",
          icon: icons.IconLoader,
          url: "/GIANT-Intelligence/My-Dashboard/maintenance",
          children: [
            {
              id: "GiantIntelligence_Overview",
              title: "Overview",
              type: "item",
              icon: icons.IconEye,
              url: "/GIANT-Intelligence/My-Dashboard/overview",
            },
            {
              id: "GiantIntelligence_ConditionMonitoring",
              title: "condition-monitoring",
              type: "item",
              icon: icons.IconZoomExclamation,
              url: "/GIANT-Intelligence/My-Dashboard/condition-monitoring",
            },
            {
              id: "GiantIntelligence_PredictiveMaintenance",
              title: "Predictive Maintenance",
              type: "item",
              icon: icons.IconTool,
              url: "/GIANT-Intelligence/My-Dashboard/predictive-maintenance",
            },
            {
              id: "GiantIntelligence_MachineStatus",
              title: "Machine Status",
              type: "item",
              icon: icons.IconActivityHeartbeat,
              url: "/GIANT-Intelligence/My-Dashboard/machine-status",
            },
          ],
        },
        {
          id: "GiantIntelligence_ProcessManagement",
          title: "process-management",
          type: "item",
          icon: icons.IconHeartRateMonitor,
          url: "/GIANT-Intelligence/My-Dashboard/process-management",
        },
        {
          id: "GiantIntelligence_SimilarityAnalysis",
          title: "similarity-analysis",
          type: "item",
          icon: icons.IconDeviceDesktopAnalytics,
          url: "/GIANT-Intelligence/My-Dashboard/similarity-analysis",
        },
        {
          id: "GiantIntelligence_OperationalExcellence",
          title: "operational-excellence",
          type: "item",
          icon: icons.IconReportAnalytics,
          url: "/GIANT-Intelligence/My-Dashboard/operational-excellence",
        },

        {
          id: "GiantIntelligence_QualityManagement",
          title: "quality-management",
          type: "item",
          icon: icons.IconZoomCheck,
          url: "/GIANT-Intelligence/My-Dashboard/quality-management",
        },
        {
          id: "GiantIntelligence_TopXVariations",
          title: "top-x-variations",
          type: "item",
          icon: icons.IconClipboardList,
          url: "/GIANT-Intelligence/My-Dashboard/top-x-variations",
        },
      ],
    },
  ],
};

export default giantintelligence;
