import * as React from 'react';
import { useSelector } from 'react-redux';
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';

import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,

    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    OutlinedInput,
    Typography,
    Avatar,
    CssBaseline,
    Link,
    Paper,
    useMediaQuery,
    
    Dialog,
    DialogContent,
    ButtonBase,
   
    
} from '@mui/material';

import {IconLogin} from "@tabler/icons";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import registerPhoto from '../../../../assets/images/register_project5.png';



// material-ui
import { useTheme } from '@mui/material/styles';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ShepherdTourContext } from 'react-shepherd';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import Google from 'assets/images/icons/social-google.svg';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import themePalette from 'themes/palette';


import axios from 'axios';

import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { SET_EMAIL, SET_LOGIN_OPEN, SET_REGISTER_OPEN } from 'store/actions';
import { useNavigate } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import { useTranslation } from 'react-i18next';
import {  getStepsNew } from 'utils/guidedTour';
import { useEffect } from 'react';

    const AuthSection = ({text = ""}) => {
        const theme = useTheme();
        const navigate = useNavigate();
        const dispatch = useDispatch();

        const { t } = useTranslation();

        const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

        const open = useSelector((state) => state.customization.isLoginOpen);
        const [openForgotPassModal, setOpenForgotPassModal] = useState(false)

       
        //const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));
        const scriptedRef = useScriptRef();
       
        const useStyles = makeStyles(theme => ({
            paper: {
                width: "100%",
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.shadows[5],
                outline: "none",
            }
          }));
        const classes = useStyles();

        const [errorMessage, setErrorMessage] = useState("")

        
        // Tour New
        const usersTour = new Shepherd.Tour({
            defaultStepOptions: {
              cancelIcon: {
                enabled: true,
              },
            },
            useModalOverlay: true,
          });
          usersTour.addSteps(getStepsNew(navigate, dispatch))
          usersTour.on('complete', () => {
          // Save to local storage that the user has completed the tour
          localStorage.setItem('usersTourCompleted', 'true');
        });

        // useEffect(()=>{
        //     usersTour.start();
        // },[])
  return (
    <>  
        <ForgotPassword openForgotPassModal={openForgotPassModal} setOpenForgotPassModal={setOpenForgotPassModal}/>
         <Box
            sx={{
            ml: 1,
            mr: 1,
            display: text ? "inline-block" : "block"
            }}
        >
            {text ? 
                <Link onClick={()=>dispatch({ type: SET_LOGIN_OPEN, isLoginOpen: true })} sx={{  borderRadius: '12px', color: "#2AA0A9", fontWeight: "bold"}}>
                    {text}
                </Link>
            : 
            <ButtonBase sx={{ borderRadius: '12px' }}>
                <Avatar
                    id="login-btn"
                    variant="rounded"
                    sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.secondary.light,
                    color: theme.palette.secondary.dark,
                    '&[aria-controls="menu-list-grow"],&:hover': {
                        background: theme.palette.secondary.dark,
                        color: theme.palette.secondary.light
                    }
                    }}
                    
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={()=>dispatch({ type: SET_LOGIN_OPEN, isLoginOpen: true })}
                    color="inherit"
                >
                    <IconLogin stroke={1.5} size="1.3rem" />
                </Avatar>
            </ButtonBase>
            }
        </Box> 

    <Dialog
        fullWidth={true} 
        maxWidth={"lg"}
        open={open}
        onClose={()=> {
                dispatch({ type: SET_LOGIN_OPEN, isLoginOpen: false });
                setErrorMessage("")
                navigate("/");
            }
        }
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        PaperProps={{ style: {
            maxHeight: '98%',
            borderRadius: "30px"
          }}}
    >
        <DialogContent  sx={{padding: "0"}}>
        <div  className={classes.paper}>
            <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{  }}>

                <CssBaseline />
                <Grid
                container
                xs={false}
                sm={4}
                md={7}
                sx={{
                    background: `linear-gradient(to right bottom, ${theme.palette.secondary.main},
                        ${theme.palette.secondary.light})`,
                    paddingTop: "2%"
                    
                }}
                >
                    
                    <Grid item>

                            <h1 style={{
                                justifyContent: "center", 
                                alignContent: "center", 
                                textAlign:"center", 
                                lineHeight: "26pt", 
                                color:"#CDF7E9", 
                                fontSize:"26px"}}>
                                <span style={{color:"#4E4E4E"}}>GIANT </span> 
                                - Your  
                                <span style={{color:"#4E4E4E"}}> G</span>eneric <span style={{color:"#4E4E4E"}}>I</span>nformation <span style={{color:"#4E4E4E"}}>AN</span>alysis <span style={{color:"#4E4E4E"}}>T</span>oolbox
                            </h1>

                            <p style={{
                                justifyContent: "center", 
                                alignContent: "center", 
                                textAlign:"center", 
                                lineHeight: "26pt", 
                                color:"#CDF7E9", 
                                fontSize:"20px",
                                fontWeight: "bold"
                                }}>
                                    {t('slogan')}
                            </p>

                            <img src={registerPhoto} alt="register-photo" style={{width:"90%", margin: "auto", paddingTop: "2%", paddingBottom: "5%", display: matchDownSM ? "none" : "block"}}/>
                    </Grid>
                </Grid>
                <Grid container xs={12} sm={8} md={5} component={Paper} elevation={6}>
                <Box
                    id="login-form"
                    sx={{
                        margin: "auto",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: "15px"
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: theme.palette.secondary.dark, color: theme.palette.secondary.light }}>
           
                    </Avatar>
                    <Typography component="h1" variant="h5" sx={{marginBottom: "5px", fontSize: "20px"}}>
                    {t('sign-in')}
                    </Typography>
                    <div style={{color: "red"}}>{errorMessage}</div>
                    <Formik
                    initialValues={{
                    email: '',
                    password: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email(t('valid-email')).max(255).required(t('required-email')),
                    password: Yup.string().max(255).required(t('required-pass')),
                })}
                

                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    console.log(values)
                    console.log(scriptedRef.current)
                    try {
                        if (scriptedRef.current) {
                            const response = await axios.post( 'https://demo.evniko-giant.com/api/v1/users/login', 
                            {username: values.email, password: values.password}, 
                            {headers: {'content-type': 'application/x-www-form-urlencoded'}});
                        console.log(response)
                            if (response.status === 200 || response.status === 201) {
                                const token = response.data.response.access_token;
                                localStorage.setItem("email", values.email)
                                dispatch({ type: SET_EMAIL, email: values.email });
                                localStorage.setItem('token', token);
                                setStatus({ success: true });
                                setSubmitting(false);
                                dispatch({ type: SET_LOGIN_OPEN, isLoginOpen: false });
                               
                                if(!localStorage.getItem('usersTourCompleted')){
                                    usersTour.start();
                                }
                            } else {
                                throw new Error(response.data.message);
                            }
                        }
                    } catch (err) {
                        console.error(err);
                        
                        setErrorMessage("Incorrect email or password. Please try again.")
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}

            >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (

                        
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <Grid container  spacing={{xs: 1}} columnSpacing={{xs:2}}>

                          
                            <Grid item xs={12} sm={12}>
                                <FormControl margin="normal" size="medium" fullWidth required error={Boolean(touched.email && errors.email)} >
                                <InputLabel htmlFor="email-login">{t('email')}</InputLabel>
                                <OutlinedInput
                                    size="medium" 
                                    label="Email"
                                    id="email-login"
                                    type="text"
                                    value={values.email}
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                />
                                {touched.email && errors.email && (
                                    <FormHelperText error id="email-login">
                                        {errors.email}
                                    </FormHelperText>
                                )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl margin="normal" size="medium" fullWidth required error={Boolean(touched.password && errors.password)} >
                                    <InputLabel htmlFor="passwor-login">{t('password')}</InputLabel>
                                    <OutlinedInput
                                        size="medium" 
                                        label="Password"
                                        id="password-login"
                                        type="password"
                                        value={values.password}
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.password && errors.password && (
                                        <FormHelperText error id="password-login">
                                            {errors.password}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                            

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            backgroundColor= "secondaryMain"

                            sx={{ mt: 3, mb: 2, backgroundColor:theme.palette.secondary.dark}}
                        >
                            {t('sign-in')}
                        </Button>

                        <Grid container>
                            
                            <Grid item  sx={{margin: "0 auto" }}>
                                <Link variant="body2" onClick={() => {
                                    dispatch({ type: SET_LOGIN_OPEN, isLoginOpen: false })
                                    dispatch({ type: SET_REGISTER_OPEN, isRegisterOpen: true })
                                }} sx={{  borderRadius: '12px', color: "#2AA0A9", fontWeight: "bold", cursor: "pointer", textAlign: "center"}}>
                                    {t('do-not-have-account')}
                                </Link>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item  sx={{margin: "0 auto" }}>
                                <Link variant="body2" onClick={() => {
                                    setOpenForgotPassModal(true)
                                }} sx={{  borderRadius: '12px', color: "#2AA0A9", fontWeight: "bold", cursor: "pointer", textAlign: "center"}}>
                                    {t('forgot-password')}
                                </Link>
                            </Grid>
                        </Grid>
                        
                    </Box>
                    )}
                    </Formik>
                </Box>
                
                </Grid>
            </Grid>
            </ThemeProvider>
            </div>
            </DialogContent>
    </Dialog>
        
    
         
    </>
  );
}
export default AuthSection;