import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import { element } from "prop-types";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);

// utilities routing
const UtilsTypography = Loadable(
  lazy(() => import("views/utilities/Typography"))
);
const UtilsColor = Loadable(lazy(() => import("views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(
  lazy(() => import("views/utilities/MaterialIcons"))
);
const UtilsTablerIcons = Loadable(
  lazy(() => import("views/utilities/TablerIcons"))
);

// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));

const GISourceNew = Loadable(
  lazy(() =>
    import("pages_GIANT/Intelligence/ChartManagement/ChartManagementNew")
  )
);
const GIPredictiveMaintenance = Loadable(
  lazy(() =>
    import(
      "pages_GIANT/Intelligence/MyDashboard/preditctiveMaintenance/predictiveMaintenance"
    )
  )
);
const Overview = Loadable(
  lazy(() => import("pages_GIANT/Intelligence/MyDashboard/overview/overview"))
);
const GIConditionMonitoring = Loadable(
  lazy(() =>
    import(
      "pages_GIANT/Intelligence/MyDashboard/conditionMonitoring/conditionMonitoring"
    )
  )
);
const GIMonitor = Loadable(
  lazy(() => import("pages_GIANT/Intelligence/MyDashboard/monitor/monitor"))
);
const GIOEE = Loadable(
  lazy(() => import("pages_GIANT/Intelligence/MyDashboard/oee/oee"))
);
const GIOpEx = Loadable(
  lazy(() =>
    import("pages_GIANT/Intelligence/MyDashboard/examplePage/dummyGrid")
  )
);
const GIMS = Loadable(
  lazy(() =>
    import(
      "pages_GIANT/Intelligence/MyDashboard/daimler/machineStatus/machineStatus"
    )
  )
);
const GITT = Loadable(
  lazy(() =>
    import(
      "pages_GIANT/Intelligence/MyDashboard/daimler/thyssenTable/thyssenTable"
    )
  )
);
const TXV = Loadable(
  lazy(() =>
    import("pages_GIANT/Intelligence/MyDashboard/topXVariations/topXVariations")
  )
);
const EmptyPage = Loadable(
  lazy(() => import("pages_GIANT/Marketing/DiscoverMore"))
);
const ManageConnections = Loadable(
  lazy(() => import("pages_GIANT/Integrator/ManageConnections"))
);
const Connections = Loadable(
  lazy(() => import("pages_GIANT/Integrator/Connections/Connections"))
);
const OpcDaConnectionInformation = Loadable(
  lazy(() =>
    import(
      "pages_GIANT/Integrator/Connections/ConnectionsOpcDa/OpcDaConnectionInformation"
    )
  )
);
const OpcUaConnectionInformation = Loadable(
  lazy(() =>
    import(
      "pages_GIANT/Integrator/Connections/ConnectionsOpcUa/OpcUaConnectionInformation"
    )
  )
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <DashboardDefault />,
    },
    {
      path: "dashboard",
      children: [
        {
          path: "default",
          element: <DashboardDefault />,
        },
      ],
    },
    {
      path: "utils",
      children: [
        {
          path: "util-typography",
          element: <UtilsTypography />,
        },
      ],
    },
    {
      path: "utils",
      children: [
        {
          path: "util-color",
          element: <UtilsColor />,
        },
      ],
    },
    {
      path: "utils",
      children: [
        {
          path: "util-shadow",
          element: <UtilsShadow />,
        },
      ],
    },
    {
      path: "icons",
      children: [
        {
          path: "tabler-icons",
          element: <UtilsTablerIcons />,
        },
      ],
    },
    {
      path: "icons",
      children: [
        {
          path: "material-icons",
          element: <UtilsMaterialIcons />,
        },
      ],
    },
    {
      path: "sample-page",
      element: <SamplePage />,
    },

    {
      path: "/GIANT-Integrator/",
      element: <ManageConnections />,
    },
    {
      path: "/GIANT-Intelligence/Chart-Management/new",
      element: <GISourceNew />,
    },
    {
      path: "/GIANT-Intelligence/My-Dashboard/predictive-maintenance",
      element: <GIPredictiveMaintenance />,
    },
    {
      path: "/GIANT-Intelligence/My-Dashboard/overview",
      element: <Overview />,
    },
    {
      path: "/GIANT-Intelligence/My-Dashboard/process-management",
      element: <EmptyPage />,
    },
    {
      path: "/GIANT-Intelligence/My-Dashboard/similarity-analysis",
      element: <EmptyPage />,
    },
    {
      path: "/GIANT-Intelligence/My-Dashboard/condition-monitoring",
      element: <GIConditionMonitoring />,
    },
    {
      path: "/GIANT-Intelligence/My-Dashboard/production-monitoring",
      element: <GIMonitor />,
    },
    {
      path: "/GIANT-Intelligence/My-Dashboard/oee",
      element: <GIOEE />,
    },
    {
      path: "/GIANT-Intelligence/My-Dashboard/anomaly-detection",
      element: <GITT />,
    },
    {
      path: "/GIANT-Intelligence/My-Dashboard/top-x-variations",
      element: <TXV />,
    },
    {
      path: "/GIANT-Intelligence/My-Dashboard/generic-dashboard",
      element: <EmptyPage />,
    },
    {
      path: "/GIANT-Intelligence/My-Dashboard/time-series-dashboard",
      element: <EmptyPage />,
    },
    {
      path: "/GIANT-Intelligence/My-Dashboard/pattern-recognition",
      element: <EmptyPage />,
    },
    {
      path: "/GIANT-Intelligence/My-Dashboard/deep-data-analysis",
      element: <EmptyPage />,
    },
    {
      path: "/GIANT-Intelligence/My-Dashboard/operational-excellence",
      // element: <GIOpEx />
      element: <EmptyPage />,
    },
    {
      path: "/GIANT-Intelligence/My-Dashboard/machine-status",
      element: <GIMS />,
    },
    {
      path: "/GIANT-Intelligence/My-Dashboard/summary-table",
      element: <GITT />,
    },
    {
      path: "/GIANT-Intelligence/My-Dashboard/quality-management",
      element: <EmptyPage />,
    },
    {
      path: "/GIANT-OpEx/My-forms",
      element: <EmptyPage />,
    },
    {
      path: "GIANT-OpEx/my-workflows/setpoint-management",
      element: <EmptyPage />,
    },
    {
      path: "GIANT-OpEx/my-workflows/Application-for-approval",
      element: <EmptyPage />,
    },
    {
      path: "/GIANT-Quality/Quality-Data",
      element: <EmptyPage />,
    },
    {
      path: "/GIANT-Quality/Reserve-Samples/Add",
      element: <EmptyPage />,
    },
    {
      path: "/GIANT-Quality/Reserve-Samples/Map-Storage",
      element: <EmptyPage />,
    },
    {
      path: "/GIANT-Quality/Reserve-Samples/Search",
      element: <EmptyPage />,
    },
    {
      path: "/GIANT-Quality/Reserve-Samples/Edit",
      element: <EmptyPage />,
    },
    {
      path: "/GIANT-Supply-Chain/My-Warehouses",
      element: <EmptyPage />,
    },
    {
      path: "GIANT-Toolbox-Manager",
      children: [
        {
          path: "GIANT-User-Manager",
          children: [
            {
              path: "New-User",
              element: <EmptyPage />,
            },
            {
              path: "Edit-Users",
              element: <EmptyPage />,
            },
            {
              path: "LDAP-synchronisation",
              element: <EmptyPage />,
            },
          ],
        },
        {
          path: "GIANT-Integrator",
          children: [
            {
              path: "Data-Sources",
              element: <ManageConnections />,
            },
            {
              path: "Connections",
              element: <Connections />,
            },
          ],
        },
        {
          path: "/GIANT-Toolbox-Manager/GIANT-Integrator/Connections/opc-da-information/:id",
          element: <OpcDaConnectionInformation />,
        },
        {
          path: "/GIANT-Toolbox-Manager/GIANT-Integrator/Connections/opc-ua-information/:id",
          element: <OpcUaConnectionInformation />,
        },
        {
          path: "GIANT-Data-Manager",
          children: [
            {
              path: "Manual-Entries",
              children: [
                {
                  path: "Manual-Paramenters",
                  children: [
                    {
                      path: "new",
                      element: <EmptyPage />,
                    },
                    {
                      path: "edit",
                      element: <EmptyPage />,
                    },
                  ],
                },
                {
                  path: "Setpoint-Management",
                  children: [
                    {
                      path: "Edit",
                      element: <EmptyPage />,
                    },
                    {
                      path: "Approve",
                      element: <EmptyPage />,
                    },
                    {
                      path: "History",
                      element: <EmptyPage />,
                    },
                  ],
                },
              ],
            },
            {
              path: "Measurement-Configuration",
              element: <EmptyPage />,
            },
            {
              path: "View-Logs",
              element: <EmptyPage />,
            },
            {
              path: "Watchdogs",
              children: [
                {
                  path: "new",
                  element: <EmptyPage />,
                },
                {
                  path: "edit",
                  element: <EmptyPage />,
                },
              ],
            },
            {
              path: "Archiving",
              children: [
                {
                  path: "Move-to-Archive",
                  element: <EmptyPage />,
                },
                {
                  path: "Restore-Archive",
                  element: <EmptyPage />,
                },
              ],
            },
          ],
        },
        {
          path: "GIANT-Operations",
          children: [
            {
              path: "Forms-Management",
              children: [
                {
                  path: "new",
                  element: <EmptyPage />,
                },
                {
                  path: "edit",
                  element: <EmptyPage />,
                },
              ],
            },
            {
              path: "Workflow-Management",
              children: [
                {
                  path: "new",
                  element: <EmptyPage />,
                },
                {
                  path: "edit",
                  element: <EmptyPage />,
                },
              ],
            },
          ],
        },
        {
          path: "GIANT-Quality",
          children: [
            {
              path: "Quality-Testing-Plan",
              children: [
                {
                  path: "new",
                  element: <EmptyPage />,
                },
                {
                  path: "edit",
                  element: <EmptyPage />,
                },
              ],
            },
          ],
        },
        {
          path: "GIANT-Supply-Chain",
          children: [
            {
              path: "Warehouse-Management",
              children: [
                {
                  path: "new",
                  element: <EmptyPage />,
                },
                {
                  path: "edit",
                  element: <EmptyPage />,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default MainRoutes;
