// assets
import {
    IconEdit,
    IconUser,
    IconFolderPlus,
    IconTools,
    IconClipboardCheck,
    IconVectorBezier,
    IconSquareCheck,
    IconHistory
} from '@tabler/icons';

// assets
import {
    IconClipboardList,
    IconCheckupList,
    IconLayoutDistributeHorizontal,
    IconBuildingWarehouse,
    IconListDetails,
    IconFileDatabase,
    IconAddressBook,
    IconSettingsAutomation,
    IconUserPlus,
    IconUserExclamation,
    IconRefresh,
    IconDatabaseImport,
    IconFilePencil,
    IconArchive,
    IconEyeCheck,
    IconFileSearch,
    IconFileSettings,
    IconPencil
} from '@tabler/icons';

// constant
const icons = {
    IconEdit,
    IconUser,
    IconFolderPlus,
    IconTools,
    IconClipboardCheck,
    IconVectorBezier,
    IconSquareCheck,
    IconHistory
};

// constant
const icons2 = {
    IconClipboardList,
    IconCheckupList,
    IconLayoutDistributeHorizontal,
    IconBuildingWarehouse,
    IconListDetails,
    IconFileDatabase,
    IconAddressBook,
    IconSettingsAutomation,
    IconUserPlus,
    IconUserExclamation,
    IconRefresh,
    IconDatabaseImport,
    IconFilePencil,
    IconArchive,
    IconEyeCheck,
    IconFileSearch,
    IconFileSettings,
    IconPencil
};
// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const gianttoolboxmanager = {
    id: 'GiantToolboxManager',
    title: 'giant-toolbox-manager',
    type: 'group',
    icon: icons.IconTools,
    children: [
        {
            id: 'GiantToolboxManager_GIANTUserManager',
            title: 'giant-user-manager',
            type: 'collapse',
            icon: icons2.IconAddressBook,
            children: [
                {
                    id: 'GiantToolboxManager_NewUser',
                    title: 'new-user',
                    type: 'item',
                    icon: icons2.IconUserPlus,
                    url: '/GIANT-Toolbox-Manager/GIANT-User-Manager/New-User'
                },
                {
                    id: 'GiantToolboxManager_EditUsers',
                    title: 'edit-users',
                    type: 'item',
                    icon: icons2.IconUserExclamation,
                    url: '/GIANT-Toolbox-Manager/GIANT-User-Manager/Edit-Users'
                },
                {
                    id: 'GiantToolboxManager_LDAPsynchronisation',
                    title: 'ldap-synchronisation',
                    type: 'item',
                    icon: icons2.IconRefresh,
                    url: '/GIANT-Toolbox-Manager/GIANT-User-Manager/LDAP-synchronisation'
                }
            ]
        },
        {
            id: 'GiantToolboxManager_GIANTIntegrator',
            title: 'giant-integrator',
            type: 'collapse',
            icon: icons2.IconSettingsAutomation,
            children: [
                {
                    id: 'GiantToolboxManager_DataSources',
                    title: 'data-sources',
                    type: 'item',
                    icon: icons2.IconDatabaseImport,
                    url: '/GIANT-Toolbox-Manager/GIANT-Integrator/Data-Sources'
                    // children: [
                    //     {
                    //         id: 'GiantToolboxManager_New',
                    //         title: 'New',
                    //         type: 'item',
                    //         icon: icons.IconFolderPlus,
                    //         url: '/GIANT-Toolbox-Manager/GIANT-Integrator/Data-Sources/new'
                    //     },
                    //     {
                    //         id: 'GiantToolboxManager_Edit',
                    //         title: 'Edit',
                    //         type: 'item',
                    //         icon: icons.IconEdit,
                    //         url: '/GIANT-Toolbox-Manager/GIANT-Integrator/Data-Sources/edit'
                    //     }
                    // ]
                }, 
                {
                    id: 'GiantToolboxManager_ActiveSubscriptions',
                    title: 'Connections',
                    type: 'item',
                    icon: icons2.IconDatabaseImport,
                    url: '/GIANT-Toolbox-Manager/GIANT-Integrator/Connections'
                }
            ]
        },
        {
            id: 'GiantToolboxManager_GIANTDataManager',
            title: 'giant-data-manager',
            type: 'collapse',
            icon: icons2.IconFileDatabase,
            children: [
                {
                    id: 'GiantToolboxManager_ManualEntries',
                    title: 'manual-entries',
                    type: 'collapse',
                    icon: icons2.IconFilePencil,
                    children: [
                        {
                            id: 'GiantToolboxManager_ManualParameters',
                            title: 'manual-parameters',
                            type: 'collapse',
                            icon: icons2.IconPencil,
                            children: [
                                {
                                    id: 'GiantToolboxManager_MP_New',
                                    title: 'new',
                                    type: 'item',
                                    icon: icons.IconFolderPlus,
                                    url: '/GIANT-Toolbox-Manager/GIANT-Data-Manager/Manual-Entries/Manual-Paramenters/new'
                                },
                                {
                                    id: 'GiantToolboxManager_MP_Edit',
                                    title: 'edit',
                                    type: 'item',
                                    icon: icons.IconEdit,
                                    url: '/GIANT-Toolbox-Manager/GIANT-Data-Manager/Manual-Entries/Manual-Paramenters/edit'
                                }
                            ]
                        },
                        {
                            id: 'GiantToolboxManager_SetpointManagement',
                            title: 'setpoint-management',
                            type: 'collapse',
                            icon: icons2.IconListDetails,
                            children: [
                                {
                                    id: 'GiantToolboxManager_SM_Edit',
                                    title: 'edit',
                                    type: 'item',
                                    icon: icons.IconEdit,
                                    url: '/GIANT-Toolbox-Manager/GIANT-Data-Manager/Manual-Entries/Setpoint-Management/Edit'
                                },
                                {
                                    id: 'GiantToolboxManager_SM_Approve',
                                    title: 'approve',
                                    type: 'item',
                                    icon: icons.IconSquareCheck,
                                    url: '/GIANT-Toolbox-Manager/GIANT-Data-Manager/Manual-Entries/Setpoint-Management/Approve'
                                },
                                {
                                    id: 'GiantToolboxManager_SM_History',
                                    title: 'history',
                                    type: 'item',
                                    icon: icons.IconHistory,
                                    url: '/GIANT-Toolbox-Manager/GIANT-Data-Manager/Manual-Entries/Setpoint-Management/History'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 'GiantToolboxManager_MeasurementConfiguration',
                    title: 'measurement-configuration',
                    type: 'item',
                    icon: icons2.IconFileSettings,
                    url: '/GIANT-Toolbox-Manager/GIANT-Data-Manager/Measurement-Configuration'
                },
                {
                    id: 'GiantToolboxManager_ViewLogs',
                    title: 'view-logs',
                    type: 'item',
                    icon: icons2.IconFileSearch,
                    url: '/GIANT-Toolbox-Manager/GIANT-Data-Manager/View-Logs'
                },
                {
                    id: 'GiantToolboxManager_Watchdogs',
                    title: 'watchdogs',
                    type: 'collapse',
                    icon: icons2.IconEyeCheck,
                    children: [
                        {
                            id: 'GiantToolboxManager_ViewLogs_New',
                            title: 'new',
                            type: 'item',
                            icon: icons.IconFolderPlus,
                            url: '/GIANT-Toolbox-Manager/GIANT-Data-Manager/Watchdogs/new'
                        },
                        {
                            id: 'GiantToolboxManager_ViewLogs_Edit',
                            title: 'edit',
                            type: 'item',
                            icon: icons.IconEdit,
                            url: '/GIANT-Toolbox-Manager/GIANT-Data-Manager/Watchdogs/edit'
                        }
                    ]
                },
                {
                    id: 'GiantToolboxManager_Archiving',
                    title: 'archiving',
                    type: 'collapse',
                    icon: icons2.IconArchive,
                    children: [
                        {
                            id: 'GiantToolboxManager_Move to Archive',
                            title: 'move-to-archive',
                            type: 'item',
                            icon: icons.IconFolderPlus,
                            url: '/GIANT-Toolbox-Manager/GIANT-Data-Manager/Archiving/Move-to-Archive'
                        },
                        {
                            id: 'GiantToolboxManager_RestoreArchive',
                            title: 'restore-archive',
                            type: 'item',
                            icon: icons2.IconRefresh,
                            url: '/GIANT-Toolbox-Manager/GIANT-Data-Manager/Archiving/Restore-Archive'
                        }
                    ]
                }
            ]
        },
        {
            id: 'GiantToolboxManagerGIANTOperations',
            title: 'giant-operations',
            type: 'collapse',
            icon: icons2.IconLayoutDistributeHorizontal,
            children: [
                {
                    id: 'GiantToolboxManager_FormsManagement',
                    title: 'forms-management',
                    type: 'collapse',
                    icon: icons2.IconClipboardList,
                    children: [
                        {
                            id: 'GiantToolboxManager_FM_New',
                            title: 'new',
                            type: 'item',
                            icon: icons.IconFolderPlus,
                            url: '/GIANT-Toolbox-Manager/GIANT-Operations/Forms-Management/new'
                        },
                        {
                            id: 'GiantToolboxManager_FM_Edit',
                            title: 'edit',
                            type: 'item',
                            icon: icons.IconEdit,
                            url: '/GIANT-Toolbox-Manager/GIANT-Operations/Forms-Management/edit'
                        }
                    ]
                },
                {
                    id: 'GiantToolboxManager_WorkflowManagement',
                    title: 'workflow-management',
                    type: 'collapse',
                    icon: icons2.IconCheckupList,
                    children: [
                        {
                            id: 'GiantToolboxManager_WF_New',
                            title: 'new',
                            type: 'item',
                            icon: icons.IconFolderPlus,
                            url: '/GIANT-Toolbox-Manager/GIANT-Operations/Workflow-Management/new'
                        },
                        {
                            id: 'GiantToolboxManager_WF_Edit',
                            title: 'edit',
                            type: 'item',
                            icon: icons.IconEdit,
                            url: '/GIANT-Toolbox-Manager/GIANT-Operations/Workflow-Management/edit'
                        }
                    ]
                }
            ]
        },
        {
            id: 'GiantQuality',
            title: 'giant-quality',
            type: 'collapse',
            icon: icons.IconClipboardCheck,
            children: [
                {
                    id: 'GiantQualityTestingPlan',
                    title: 'quality-testing-plan',
                    type: 'collapse',
                    icon: icons2.IconFileSearch,
                    children: [
                        {
                            id: 'GiantQualityT_New',
                            title: 'new',
                            type: 'item',
                            icon: icons.IconFolderPlus,
                            url: '/GIANT-Toolbox-Manager/GIANT-Quality/Quality-Testing-Plan/new'
                        },
                        {
                            id: 'GiantQualityT_Edit',
                            title: 'edit',
                            type: 'item',
                            icon: icons.IconEdit,
                            url: '/GIANT-Toolbox-Manager/GIANT-Quality/Quality-Testing-Plan/edit'
                        }
                    ]
                }
            ]
        },
        {
            id: 'GIANTSupplyChain',
            title: 'giant-supply-chain',
            type: 'collapse',
            icon: icons.IconVectorBezier,
            children: [
                {
                    id: 'WarehouseManagement',
                    title: 'warehouse-management',
                    type: 'collapse',
                    icon: icons2.IconBuildingWarehouse,
                    children: [
                        {
                            id: 'GiantSCW_New',
                            title: 'new',
                            type: 'item',
                            icon: icons.IconFolderPlus,
                            url: '/GIANT-Toolbox-Manager/GIANT-Supply-Chain/Warehouse-Management/new'
                        },
                        {
                            id: 'GiantSCW_Edit',
                            title: 'edit',
                            type: 'item',
                            icon: icons.IconEdit,
                            url: '/GIANT-Toolbox-Manager/GIANT-Supply-Chain/Warehouse-Management/edit'
                        }
                    ]
                }
            ]
        }
    ]
};

export default gianttoolboxmanager;
