// assets
import { IconVectorBezier, IconBuildingWarehouse } from '@tabler/icons';

// constant
const icons = { IconVectorBezier, IconBuildingWarehouse };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const giantsupplychain = {
    id: 'GiantSupplyChain',
    title: 'giant-supply-chain',
    type: 'group',
    icon: icons.IconVectorBezier,
    children: [
        {
            id: 'GiantSupplyChain_MyWarehouses',
            title: 'my-warehouses',
            type: 'item',
            icon: icons.IconBuildingWarehouse,
            url: '/GIANT-Supply-Chain/My-Warehouses'
        }
    ]
};

export default giantsupplychain;
