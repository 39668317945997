import { SET_MENU } from "store/actions";

const getSteps = (navigate, dispatch) => {
    return [
    
    {
        id: '1',
        attachTo: { element: '#total-energy-consumption', on: 'left-end' },
        buttons: [
            
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                action() {
                    return this.next();
                },
            }
        ],
        scrollTo: false,
        cancelIcon: {
        enabled: true,
        },
        beforeShowPromise: function() {
            return new Promise(function(resolve) {
                dispatch({ type: SET_MENU, opened: true });
                // const openDashboards = () => {
                //     const oeeElement = document.getElementById('GiantIntelligence_OEE');
                //     const element = document.getElementById('GiantIntelligence_MyDashboards');
                //     if (element && !oeeElement) {
                //         console.log("clicked")
                //         element.firstChild.click();
                //     } else {
                //         console.log("not found")
                //     }
                // }
                // openDashboards()
                resolve();
              
            });
          },
        title: 'Best-in-class User Experience',
        text: ['Explore your data effortless by switching time periods and filtering components with one click.'],

    },
    {
        id: '2',
        attachTo: { element: '#login-btn', on: 'top' },
        buttons: [
            
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                action() {
                    return this.next();
                },
            }
        ],
        
        
        scrollTo: true,
        cancelIcon: {
        enabled: true,
        },
        title: 'Sign in for more',
        text: ["Sign in to proceed with the tour of еvniko’s GIANT - the Generic Information and ANalysis Toolbox - and explore further modules for OEE, Condition Monitoring, and others."],
        
    },
]}

const getStepsNew = (navigate, dispatch) => {
    return [
        {
            id: '1',
            attachTo: { element: '#GiantIntelligence_MyDashboards', on: 'right' },
            buttons: [
                
                {
                    classes: 'shepherd-button-primary',
                    text: 'Next',
                    action() {
                        return this.next();
                    },
                }
            ],
            scrollTo: false,
            cancelIcon: {
            enabled: true,
            },
            beforeShowPromise: function() {
                return new Promise(function(resolve) {
                    dispatch({ type: SET_MENU, opened: true });
                    const openDashboards = () => {
                        const oeeElement = document.getElementById('GiantIntelligence_OEE');
                        const element = document.getElementById('GiantIntelligence_MyDashboards');
                        if (element && !oeeElement) {
                            console.log("clicked")
                            element.firstChild.click();
                        } else {
                            console.log("not found")
                        }
                    }
                    openDashboards()
                    resolve();
                  
                });
              },
            title: 'Custom Dashboards',
            text: ['Select a dashboard from the navigation bar on the left and explore...'],
    
        },
    {
        id: '2',
        attachTo: { element: '#GiantIntelligence_OEE', on: 'right-start' },
        buttons: [
            
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                action() {
                    return this.next();
                },
            }
        ],
        beforeShowPromise: function() {
            return new Promise(function(resolve) {
              navigate("/GIANT-Intelligence/My-Dashboard/oee")
                resolve();
              
            });
          },
        scrollTo: false,
        cancelIcon: {
        enabled: true,
        },
        title: ['Overall Equipment Efficiency'],
        text: ['Use established methods like OEE to get insights of your manufacturing processes on every level of detail. Enjoy out-of-the box analyses to optimize your quality, availability, and overall performance.'],
    },
   
    {
        id: '3',
        attachTo: { element: '#GiantIntelligence_ConditionMonitoring', on: 'right-start' },
        buttons: [
            
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                action() {
                    return this.next();
                },
            }
        ],
        beforeShowPromise: function() {
            return new Promise(function(resolve) {
                navigate('/GIANT-Intelligence/My-Dashboard/condition-monitoring')
                resolve();
            });
        },
        scrollTo: false,
        cancelIcon: {
        enabled: true,
        },
        title: 'Condition Monitoring',
        text: ['Use aggregated views on the status of your machines to track risks and steer resources effectively. Click on the status of a machine to get further details.'],
        
    },

    {
        id: '4',
        attachTo: { element: '#GiantIntelligence_TopXVariations', on: 'right-start' },
        buttons: [
            
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                action() {
                    return this.next();
                },
            }
        ],
        beforeShowPromise: function() {
            return new Promise(function(resolve) {
                navigate('/GIANT-Intelligence/My-Dashboard/top-x-variations')
                resolve();
            });
        },
        scrollTo: false,
        cancelIcon: {
        enabled: true,
        },
        title: 'Top X Variations',
        text: ['Use advanced methods like boxplots to get insights which parameters in your system have the biggest variations over time and thus could be an indicator for undesirable process instability.'],
        
    },
   
    {
        id: '5',
        attachTo: { element: '#production-monitoring-menubar', on: 'bottom' },
        buttons: [
            
            {
                classes: 'shepherd-button-primary',
                text: 'Start exploring GIANT...',
                action() {
                    return this.next();
                },
            }
        ],
        beforeShowPromise: function() {
            return new Promise(function(resolve) {
                navigate('/GIANT-Intelligence/My-Dashboard/production-monitoring')
                setTimeout(function(){

                    resolve();
                }, 500); 

            });
        },
        scrollTo: false,
        cancelIcon: {
        enabled: true,
        },
        title: 'Production Monitoring',
        text: ['Select parameters and time periods to instantly plot data according to your current challenge and run analyses for quick decision making.'],
        
    },
    
]}
export {getSteps, getStepsNew};