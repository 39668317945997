import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslations from '../assets/localization/en.json';
import deTranslations from '../assets/localization/de.json';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
.use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  
  .init({
    resources: {
      en: {
        translation: enTranslations,
      },
      de: {
        translation: deTranslations,
      },
    },
    fallbackLng: "en", // if the current language translations are not found, it will fall back to English
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;