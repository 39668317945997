import { ButtonBase, Container, Modal, ThemeProvider, Typography, useMediaQuery,} from '@mui/material';
import { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useTheme } from '@mui/material/styles';

import { IconMail } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

function getModalStyle() {
    const top = 50 ;
    const left = 50 ;

    return {

       top: `${top}%`,
       left: `${left}%`,
       transform: `translate(-${top}%, -${left}%)`,
       borderRadius: "20px",
    };
}
export default function ConfirmationModal ({open, setOpen}){
    const [modalStyle] = useState(getModalStyle);
    const theme = useTheme();

    const { t } = useTranslation();

    const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const useStyles = makeStyles(theme => ({
        paper: {
            position: "absolute",
            width: matchesMdDown ? "90%": "40%",
            padding: "30px",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            
            outline: "none",
          
        }
      }));
    const classes = useStyles();

    return (
        <>
            <Modal
            open={open}
            onClose={()=> setOpen(false)}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            >
                <div style={modalStyle} className={classes.paper}>
                    <ThemeProvider theme={theme}>
                        <Container component="main" style={{paddingLeft: "0", paddingRight: "0"}}>
                            
                            

                            <Typography>
                                <Typography variant="h2" align="center" sx={{margin: "0", pb: 2}}>
                                
                                    <IconMail stroke={1.5} size="1.6rem" style={{ verticalAlign: "middle"}} /> 
                                    {t('cofirm-email')}
                                </Typography>

                                <Typography variant="body2" align="justify" sx={{mb: 2}}>
                                    {t('thank-you-for-signing-up')}
                                </Typography>

                                <Typography variant="body2" align="justify" sx={{mb: 2}}>
                                    {t('check-your-email')}
                                </Typography>

                                <Typography variant="body2" align="justify">
                                    {t('verification-info')}
                                </Typography>
                                
                            </Typography>
                        </Container>
                    </ThemeProvider>
                </div>
            
            </Modal>
        </>

    )  
}