import logo from '../assets/img/logo.png';
import '@fontsource/montserrat';
import '@fontsource/roboto/900.css';

const Logo = () => {
    return (
        <div>
            <img src={logo} alt="Giant" width="23" style={{ verticalAlign: 'text-bottom' }} />
            <span style={{ fontWeight: '900', fontSize: '1.6rem', color: '#247e85', fontFamily: 'Montserrat' }}>&nbsp;&nbsp;evniko GIANT</span>
        </div>
    );
};

export default Logo;
