import { ButtonBase, Container, Modal, ThemeProvider, Typography, useMediaQuery, Box, Grid, FormControl, FormHelperText, OutlinedInput, InputLabel, Avatar, Button} from '@mui/material';

import {IconMailForward} from "@tabler/icons";

import { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useTheme } from '@mui/material/styles';

// project imports
import useScriptRef from 'hooks/useScriptRef';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

import axios from 'axios';
import { useTranslation } from 'react-i18next';


function getModalStyle() {
    const top = 50 ;
    const left = 50 ;

    return {

       top: `${top}%`,
       left: `${left}%`,
       transform: `translate(-${top}%, -${left}%)`,
       borderRadius: "20px",
    };
}

const ForgotPassword = ({openForgotPassModal, setOpenForgotPassModal}) => {
    const [modalStyle] = useState(getModalStyle);
    const theme = useTheme();

    const { t } = useTranslation();

    const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const scriptedRef = useScriptRef();


    const useStyles = makeStyles(theme => ({
        paper: {
            position: "absolute",
            width: matchesMdDown ? "90%": "40%",
            padding: "30px",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            
            outline: "none",
          
        }
      }));
    const classes = useStyles();

    return(

        <>
        <Modal
        open={openForgotPassModal}
        onClose={()=> setOpenForgotPassModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <ThemeProvider theme={theme}>
                    <Container component="main" style={{paddingLeft: "15px", paddingRight: "15px", textAlign: "center"}}>
                        <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                            background: theme.palette.secondary.dark,
                            color: theme.palette.secondary.light,
                            margin: "auto"
                        }
                        }}
                        
                        aria-controls={ 'menu-list-grow'}
                        aria-haspopup="true"
                        
                        color="inherit"
                    >
                        <IconMailForward stroke={1.5} size="1.3rem" />
                    </Avatar>
                        <Typography component="h1" variant="h5" sx={{ marginBottom: "5px", fontSize: "20px", padding: "5px"}}>
                            {t('forgot-password')}
                        </Typography>

                        <Typography component="h2" variant="h5" sx={{ marginBottom: "5px", fontSize: "14px", padding: "5px" }}>
                            {t('reset-password')}
                        </Typography>

                        <Formik
                            initialValues={{
                                email: '',
                                
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().email(t('valid-email')).max(255).required(t('required-email')),
                            })}

                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                console.log(values)
                                console.log(scriptedRef.current)
                                try {
                                    if (scriptedRef.current) {
                                        const response = await axios.post('https://demo.evniko-giant.com/api/v1/users/reset_password', null, {
                                            params: {
                                                email: values.email
                                            }
                                        });
                                        console.log(response)
                                        if (response.status === 200 || response.status === 201) {
                                            setStatus({ success: true });
                                            setSubmitting(false);
                                            setOpenForgotPassModal(false)
                                        } else {
                                            throw new Error(response.data.message);
                                        }
                                    }
                                } catch (err) {
                                    console.error(err);
                                    if (scriptedRef.current) {
                                        setStatus({ success: false });
                                        setErrors({ submit: err.message });
                                        setSubmitting(false);
                                    }
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                    <Grid item xs={12} sm={12}>
                                        <FormControl margin="normal" size="medium" fullWidth required error={Boolean(touched.email && errors.email)} >
                                            <InputLabel htmlFor="outlined-adornment-email-register">{t('email-username')}</InputLabel>
                                            <OutlinedInput
                                                size="medium"
                                                label="Email Address / Username"
                                                id="outlined-adornment-email-register"
                                                type="email"
                                                value={values.email}
                                                name="email"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                inputProps={{}}
                                            />

                                            <FormHelperText error sx={{ height: "10px", marginTop: "0px", marginBottom: "4px" }} id="standard-weight-helper-text--register">
                                                {touched.email && errors.email && (
                                                    errors.email
                                                )}
                                            </FormHelperText>

                                        </FormControl>

                                        <Button
                                            type="submit"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                            backgroundColor="secondaryMain"
                                            sx={{ mt: 1, mb: 1, backgroundColor: theme.palette.secondary.dark, fontSize: "16px" }}
                                        >
                                            {t('continue')}
                                        </Button>
                                    </Grid>
                                </Box>


                            )}
                        </Formik>

                        

                        
                    </Container>
                </ThemeProvider>
            </div>
        
        </Modal>
    </>

    )

}

export default ForgotPassword;