// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_EMAIL = '@customization/SET_EMAIL';
export const SET_LOGIN_OPEN = '@customization/SET_LOGIN_OPEN';
export const SET_REGISTER_OPEN = '@customization/SET_REGISTER_OPEN';
export const SET_CONFIRMRESET_OPEN = '@customization/SET_CONFIRMRESET_OPEN';





